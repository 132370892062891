import analyticstracker from "@jmc/analyticstracker";
import { onLCP, onFID, onCLS, onINP, Metric } from "web-vitals";
import React, { useState } from "react";
import { EventTypes } from "../../types";

type TrackingDivProps = { report: Metric };

export const TrackingDiv = ({ report }: TrackingDivProps): JSX.Element => {
    const { name, value, delta, entries, id } = report;

    const eventInfo = {
        id: id,
        name: name,
        value: value,
        delta: delta,
        entries: entries,
    };

    const event = {
        event: EventTypes.WEBVITALS_METRIC,
        info: eventInfo,
    };

    analyticstracker().trackEvent(event);

    try {
        return (
            <div
                role="none"
                id={id}
                data-test-id={`${EventTypes.WEBVITALS_METRIC}-${name}`}
                data-tracking-event={EventTypes.WEBVITALS_METRIC}
                data-tracking-info={JSON.stringify(eventInfo)}
            />
        );
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
    }
};

type TrackingProps = {
    metric: "LCP" | "FID" | "CLS" | "INP";
};

export const Tracking = ({ metric }: TrackingProps): JSX.Element => {
    const [report, setReport] = useState<Metric | null>(null);

    React.useEffect(() => {
        let unmounted = false;
        switch (metric) {
            case "LCP":
                onLCP((d) => {
                    if (!unmounted) {
                        setReport(d);
                    }
                });
                break;
            case "CLS":
                onCLS(
                    (d) => {
                        if (!unmounted) {
                            setReport(d);
                        }
                    },
                    { reportAllChanges: true },
                );
                break;
            case "INP":
                onINP((d) => {
                    if (!unmounted) {
                        setReport(d);
                    }
                });
                break;
            default:
                onFID((d) => {
                    if (!unmounted) {
                        setReport(d);
                    }
                });
        }
        return () => {
            unmounted = true;
        };
    }, []);

    if (!report) return <></>;

    return <TrackingDiv report={report} />;
};

const WebVitalsTracking = (): JSX.Element => {
    return (
        <div id="webvitals-tracking">
            <Tracking metric="CLS" />
            <Tracking metric="FID" />
            <Tracking metric="LCP" />
            <Tracking metric="INP" />
        </div>
    );
};

export default WebVitalsTracking;
