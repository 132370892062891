import React, { useEffect } from "react";
import style from "./style.module.scss";
import { keyHandler } from "@jmc/utils/utils/keyHandler";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { mdiClose } from "@mdi/js";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useLocalStorage } from "react-use";
import { useTranslation } from "react-i18next";

export interface JNJBarPropTypes {
    text: string;
    links?: JSX.Element[]; // Array of link components (JMCLink or JWMLink)
    setWithJNJBar?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Add a bar to display an information message and links.
 * It can be dismissed by clicking on the close button.
 * Once dismissed, it will not be shown again.
 *
 * Developer notes:
 *  - Once dismissed, if you want to show it again, type this in the browser console:
 *     localStorage.removeItem("jnjBarIsClosed");
 */
export const JNJBar = ({ text, links, setWithJNJBar }: JNJBarPropTypes): JSX.Element => {
    const [isClosed, setIsClosed] = useLocalStorage("jnjBarIsClosed", false);
    const isBrowser = typeof window !== "undefined";
    const showBar = !isClosed && isBrowser && !!text;
    const { t } = useTranslation();

    const onClickClose = () => {
        setIsClosed(true);
        setWithJNJBar && setWithJNJBar(false);
    };

    const onEnterClose = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
        keyHandler(e.key, () => onClickClose());
    };

    useEffect(() => {
        setWithJNJBar && setWithJNJBar(showBar);
    }, []);

    return showBar ? (
        <div className={style.jnjBar} data-test-id="JNJ-Bar">
            <div className={style.container}>
                <div className={style.content}>
                    <Typography font="inherit" color="white">
                        {text}
                    </Typography>
                    {links}
                </div>
                <div className={style.closeIcon}>
                    <button
                        id="JNJ-Bar.CloseButton"
                        tabIndex={0}
                        onClick={onClickClose}
                        onKeyUp={onEnterClose}
                        aria-label={t("Close", { ns: "common" })}
                        data-test-id="JNJ-Bar.CloseButton"
                    >
                        <Icon icon={mdiClose} verticalAlign="middle" color={"white"} />
                    </button>
                </div>
            </div>
        </div>
    ) : null;
};

export default JNJBar;
