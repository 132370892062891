import React from "react";
import style from "./style.module.scss";
import classnames from "classnames";
import { mdiClose } from "@mdi/js";
import { Icon } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export type TagColor = "primary" | "default";

interface PropTypes {
    children: JSX.Element | string;
    dismissable?: boolean;
    onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
    color?: TagColor;
    blueHover?: boolean;
    enableHover?: boolean;
}

/**
 * TODO: After rebranding finishes
 *  1. Remove the `color` prop cause there are only grey tags in the new design.
 *  2. Icon color will not be used.
 *  3. The `blueHover` prop is also not needed, enableHover will be enough.
 *  4. Make sure that all calls to this component use a typography with size "s" rather than "xs".
 */
export const Tag = ({
    children,
    dismissable,
    enableHover = true,
    onClick,
    color = "default",
    blueHover = false,
    ...other
}: PropTypes) => {
    const { jnjFullBranded } = useJnjBranding();
    const iconColor = color === "default" || jnjFullBranded ? null : "white";

    return (
        <button
            type="button"
            className={classnames(
                style.tag,
                enableHover && style.hover,
                blueHover && style.blueHover,
                dismissable && style.dismissable,
                style[`tag__${color}`],
            )}
            data-test-id={`Tag.${children?.props?.children || children}`}
            onClick={onClick}
            {...other}
        >
            <div className={classnames(style[`tag__label__${color}`])} data-test-id="Tag.Label">
                {children}
            </div>
            {Boolean(dismissable) && (
                <div className={style.tag__icon}>
                    <Icon
                        icon={mdiClose}
                        size={jnjFullBranded ? "1.2rem" : "0.8rem"}
                        verticalAlign="middle"
                        color={iconColor}
                        data-test-id="Tag.CloseButton"
                    />
                </div>
            )}
        </button>
    );
};

Tag.displayName = "Tag";
