import "@algolia/autocomplete-theme-classic/dist/theme.css";

import { AutocompleteOptionsWithMetadata, AutocompleteState, BaseItem } from "@algolia/autocomplete-core";
import analyticstracker from "@jmc/analyticstracker";
import Collection from "@jmc/core/src/components/AutoComplete/Collection/Collection";
import { AutoCompleteType } from "@jmc/core/src/components/AutoComplete/types";
import { EventTypes } from "@jmc/core/src/types/EventTypes";
import { Icon, jnjCloseNaked, jnjSearch } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { TextField } from "@jmc/solid-design-system/src/components/atoms/TextField/TextField";
import ClientOnly from "@jmc/utils/utils/client-only";
import { mdiClose, mdiMagnify } from "@mdi/js";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import style from "./style.module.scss";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

interface PropTypes extends AutocompleteOptionsWithMetadata<BaseItem> {
    autocomplete: AutoCompleteType;
    autocompleteState: AutocompleteState<BaseItem>;
    plugins: Record<string, unknown>[];
    indexName: string;
    onSelect: (query: string) => void;
    inputRef: React.MutableRefObject<HTMLInputElement>;
    containerRef: React.MutableRefObject<HTMLInputElement>;
    navigate: (query: string) => void;
    voiceButton?: JSX.Element;
    voiceOverlay?: JSX.Element;
    enablePreviousSearches?: boolean;
    enablePopularSearches?: boolean;
    enableAutocomplete?: boolean;
}

export function SearchBox(props: PropTypes): JSX.Element {
    const { t } = useTranslation();
    const formRef = React.useRef(null);
    const {
        inputRef,
        containerRef,
        autocomplete,
        autocompleteState,
        navigate,
        voiceButton,
        voiceOverlay,
        enablePreviousSearches = true,
        enablePopularSearches = true,
        enableAutocomplete = true,
    } = props;
    const [focus, setFocus] = useState(false);
    const expanded = autocomplete.getRootProps({})?.["aria-expanded"];
    const [searchIconColor, changeColor] = useState<any>("text-grey-300");
    const { jnjFullBranded } = useJnjBranding();

    useEffect(() => {
        const query = autocompleteState?.query;
        if (query && query !== "") {
            const tracking = setTimeout(() => {
                analyticstracker().trackEvent({
                    event: EventTypes.SEARCH_QUERY,
                    info: {
                        keyword: query,
                        type: "general",
                    },
                });
            }, 500);

            return (): void => {
                clearTimeout(tracking);
            };
        }
    }, [autocompleteState?.query]);

    const resetTabFocus = (): void => {
        setFocus(false);
    };

    const handleOnFocus = (e: Event): void => {
        const { onFocus } = autocomplete.getInputProps({});
        onFocus(e);
        changeColor("text-dark");
    };

    const handleOnBlur = (): void => {
        const { onBlur } = autocomplete.getInputProps({});

        onBlur();
        resetTabFocus();
        autocomplete.setIsOpen(false);
        changeColor("text-grey-300");
    };

    const handleOnReset = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const { onReset } = autocomplete.getFormProps({ inputElement: inputRef.current });
        event.preventDefault();
        onReset(event as unknown as Event);
    };

    const displayOpen = (): boolean => {
        if (autocompleteState.isOpen) {
            return (
                (autocompleteState.query === "" && (enablePopularSearches || enablePreviousSearches)) ||
                (autocompleteState.query !== "" && enableAutocomplete)
            );
        }
        return false;
    };

    // Use useRef to store the original overflow style of the body.
    const bodyOverFlowOriginalState = useRef("auto");

    useEffect(() => {
        // If expanded is true, store the current overflow style and set it to 'hidden'.
        if (expanded) {
            bodyOverFlowOriginalState.current = document.body.style.overflowY;
            document.body.style.overflowY = "hidden";
        }
        // If expanded is false, restore the original overflow style.
        else {
            document.body.style.overflowY = bodyOverFlowOriginalState.current;
        }

        // Cleanup function: This function will run when the component unmounts and before subsequent re-renders.
        // Here, it's used to restore the original overflow style when the component unmounts.
        return () => {
            document.body.style.overflowY = bodyOverFlowOriginalState.current;
        };
    }, [expanded]);

    return (
        <>
            {voiceOverlay && <div className="aa-VoiceSearchOverlay">{voiceOverlay}</div>}
            <div
                ref={containerRef}
                className={classnames("aa-Autocomplete", style.autocomplete, displayOpen() ? style.open : null)}
                role="presentation"
                {...autocomplete.getRootProps({})}
                onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                        e.preventDefault();
                        e.stopPropagation();
                    }
                }}
            >
                <form
                    ref={formRef}
                    className={classnames("aa-Form", style.form)}
                    {...autocomplete.getFormProps({ inputElement: inputRef.current })}
                    onSubmit={(e: React.FormEvent) => {
                        e.preventDefault();
                        const query = (autocomplete.getInputProps({}) as unknown as { value: string }).value;
                        if (query !== "") {
                            autocomplete.getFormProps({ inputElement: inputRef.current }).onSubmit(e);
                            autocomplete.getFormProps({ inputElement: inputRef.current }).onReset(e);
                            navigate(query);
                        }
                    }}
                >
                    <div className={classnames("aa-InputWrapper", style.inputWrapper)}>
                        <TextField
                            name="SearchBoxHeader"
                            className={classnames("aa-Input", style.searchBox, focus ? style.outLine : null)}
                            ref={inputRef}
                            {...autocomplete.getInputProps({})}
                            onFocus={(e: Event) => handleOnFocus(e)}
                            onBlur={() => handleOnBlur()}
                            data-test-id={"Autocomplete-SearchBox"}
                            type="text"
                            placeholder={t("Search ...", { ns: "search" })}
                            border={false}
                            aria-label={t("Search", { ns: "search" })}
                            startAdornment={
                                <Icon
                                    onClick={() => {
                                        const inputValue = autocomplete.getInputProps({}).value;
                                        if (inputValue === "") {
                                            inputRef.current.focus();
                                        } else {
                                            navigate(inputValue);
                                        }
                                    }}
                                    color={jnjFullBranded ? "primary" : searchIconColor}
                                    data-test-id={`search-box-search-icon`}
                                    icon={jnjFullBranded ? jnjSearch : mdiMagnify}
                                    type={jnjFullBranded ? "jnj" : "mdi"}
                                    verticalAlign="middle"
                                    size={jnjFullBranded ? "medium" : "small"}
                                />
                            }
                            endAdornment={
                                <>
                                    {(autocomplete.getInputProps({}).value !== "" || voiceButton) && (
                                        <div className={classnames("aa-InputWrapperSuffix", style.suffix)}>
                                            {voiceButton}
                                            {autocomplete.getInputProps({}).value !== "" && (
                                                <button
                                                    className={classnames("aa-ClearButton", style.clearSearch)}
                                                    data-test-id={`search-box-close-icon`}
                                                    onClick={(event) => handleOnReset(event)}
                                                    type="reset"
                                                    aria-label={t("Clear search", { ns: "search" })}
                                                >
                                                    <Icon
                                                        size="small"
                                                        color="black"
                                                        icon={jnjFullBranded ? jnjCloseNaked : mdiClose}
                                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                                    />
                                                </button>
                                            )}
                                        </div>
                                    )}
                                </>
                            }
                        />
                    </div>
                </form>

                <ClientOnly>
                    {displayOpen() && (
                        <div
                            id="aa-Panel"
                            className={classnames("aa-Panel", style.panel)}
                            {...autocomplete.getPanelProps({})}
                        >
                            {autocompleteState.collections.map((collection, index) => (
                                <Collection
                                    key={`collection-${index}`}
                                    collection={collection}
                                    autocomplete={autocomplete}
                                    query={autocompleteState?.query}
                                    plugins={props.plugins}
                                />
                            ))}
                        </div>
                    )}
                </ClientOnly>
            </div>
            <div className={classnames(style.overlay, expanded ? style.visible : null)} />
        </>
    );
}

export default SearchBox;
