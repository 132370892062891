import React from "react";
import style from "./style.module.scss";
import { Typography } from "../Typography/Typography";
import { Icon, jnjOpenQuotation, jnjCloseQuotation } from "../Icon/Icon";
import { useMediaQuery, BreakPoint } from "../../../hooks/useMediaQuery";

interface PropTypes {
    children: JSX.Element | string;
    author?: string;
}
export const Quote = ({ children, author }: PropTypes) => {
    const isLowerThanTablet = useMediaQuery(BreakPoint.lg);
    const closeQuoteIconSize = isLowerThanTablet ? "xs" : "medium";

    return (
        <div className={style.quote}>
            <Icon color="primary" icon={jnjOpenQuotation} type="jnj" verticalAlign="middle" size="xl" />
            <div>
                <>
                    <Typography variant="h4" color="inherit">
                        {children}
                        <Icon
                            color="primary"
                            icon={jnjCloseQuotation}
                            type="jnj"
                            verticalAlign="top"
                            size={closeQuoteIconSize}
                        />
                    </Typography>
                    {author && (
                        <Typography variant="h5" color="inherit">
                            - {author}
                        </Typography>
                    )}
                </>
            </div>
        </div>
    );
};
