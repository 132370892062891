// CMS access levels
export const ACCESS_LEVEL_1 = "Anonymous (L1)";
export const ACCESS_LEVEL_2 = "Unverified HCP (L2)";
export const ACCESS_LEVEL_3 = "Verified HCP (L3)";

// CMS 201/301 types
export const PROMOTIONAL_CONTENT = "Promotional Content (201)";
export const MEDICAL_CONTENT = "Medical Content (301)";

//Levels
export const LEVEL_1 = "level-1";
export const LEVEL_2 = "level-2";
export const LEVEL_3 = "level-3";

export const PURPOSE_PROMOTE = "promote";
export const PURPOSE_EDUCATE = "educate";
export const PURPOSE_SUPPORT = "support";

export const TYPE_OF_INTERACTION_COMMERCIAL = "commercial";
export const TYPE_OF_INTERACTION_MEDICAL = "medical";

export const NOT_APPLICABLE = "not applicable";

export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";

export const SERVICES_URL = "/services";
export const NEWS_CENTER_URL = "/services/news-center";
export const PODCAST_CENTER_URL = "/services/podcast-center";
export const REQUEST_MATERIALS_URL = "/services/request-materials";
export const MEDIA_CENTER_URL = "/services/media-center";
export const MYJMC_URL = "/services/my-jmc";
export const SUBSCRIPTIONS_URL = "/subscriptions";
export const LEARNING_CENTER_URL = "/services/learning-center";
export const LEARNING_CENTER_COMPLETED_COURSES_URL = "/services/learning-center/my-completed-courses";
export const EVENT_URL = "/services/events";
export const IOD_URL =
    process.env.GATSBY_JNJ_BRANDED === "full" ? "/services/contact-jnj" : "/services/contact-janssen";
export const SPECIALTIES_URL = "/services/specialties";
export const TOV_URL = "/services/transfer-of-value";
export const PUBMED_URL = "/services/pubmed";
export const PUBMED_ARTICLES_URL = "/services/my-jmc/my-articles";
export const EVENT_CERTIFICATES_URL = "/services/my-jmc/my-certificates";
export const DRUGBANK_URL = "/services/drug-to-drug";
export const SMPC_URL = "/services/smpc-detail";
export const TCP_URL = "/services/tcp";
export const PRODUCT_GLOSSARY_URL = "/services/product-glossary";
export const SCIENTIFIC_PUBLICATIONS_URL = "/services/scientific-publications";
export const ATE_URL = "/services/ask-the-experts";
export const SEARCH_URL = "/services/search";
// ATTENTION: If you add a service URL here, also add in to the array below ALL_JMC_SERVICES

// Used to map service urls with underscores to correct urls with hyphens.
// This is a work around for internal links which use a preloaded Gatsby Link (RTEs) and don't request the page from the IAC FE.
export const SERVICES_URLS_MAPPINGS = {
    "/services/news_center": NEWS_CENTER_URL,
    "/services/request_materials": REQUEST_MATERIALS_URL,
    "/services/podcast_center": PODCAST_CENTER_URL,
    "/services/media_center": MEDIA_CENTER_URL,
    "/services/learning_center": LEARNING_CENTER_URL,
    "/services/contact_janssen": IOD_URL,
    "/services/product_glossary": PRODUCT_GLOSSARY_URL,
    "/services/scientific_publications": SCIENTIFIC_PUBLICATIONS_URL,
};

export const ALL_JMC_SERVICES = [
    REQUEST_MATERIALS_URL,
    MEDIA_CENTER_URL,
    NEWS_CENTER_URL,
    PODCAST_CENTER_URL,
    EVENT_URL,
    SCIENTIFIC_PUBLICATIONS_URL,
    MYJMC_URL,
    LEARNING_CENTER_URL,
    EVENT_URL,
    IOD_URL,
    SPECIALTIES_URL,
    TOV_URL,
    PUBMED_URL,
    TCP_URL,
    PRODUCT_GLOSSARY_URL,
    SCIENTIFIC_PUBLICATIONS_URL,
    ATE_URL,
    SEARCH_URL,
    DRUGBANK_URL,
];
export const SERVICES_ACCESSIBLE_WITH_MARKETING_TOKEN = [
    REQUEST_MATERIALS_URL,
    MEDIA_CENTER_URL,
    NEWS_CENTER_URL,
    EVENT_URL,
    SCIENTIFIC_PUBLICATIONS_URL,
    DRUGBANK_URL,
    PUBMED_URL,
    PODCAST_CENTER_URL,
];

export const SERVICES_SUBPAGES_INACCESSIBLE_WITH_MARKETING_TOKEN = [`${REQUEST_MATERIALS_URL}/order`];

export const ONE_TRUST_MODULE = {
    POLICY_NOTICE_MANAGEMENT: "Policy & Notice Management",
    PRIVACY_NOTICE_MANAGEMENT: "Privacy Notice Management", // new OneTrust module
};

export const imageFragment = `
title
url
description
dimension {
    height
    width
}
 gatsbyImageData(layout: FULL_WIDTH)
`;

export const imageBlockFragment = `
image {
    fit
    alt_text
    caption
    title
    enable_lightbox
    horizontal_alignment
    link_group_image {
        anchor_id
        internal_link_parameters
        link_continued
        internal_link {
            ... on Contentstack_page {
                page_url: url
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_request_materials {
                request_materials_uid: uid
            }
            ... on Contentstack_media_center {
                media_center_uid: uid
            }
            ... on Contentstack_service_overview {
                service_overview_uid: uid
            }
            ... on Contentstack_news_center {
                news_center_uid: uid
            }
            ... on Contentstack_ask_the_experts {
                ask_the_experts_uid: uid
            }
            ... on Contentstack_drug_to_drug {
                drug_to_drug_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_learning_center {
                lms_uid: uid
            }
            ... on Contentstack_podcast_center {
                podcast_center_uid: uid
            }
            ... on Contentstack_specialties {
                specialties_uid: uid
            }
            ... on Contentstack_transfer_of_value {
                transfer_of_value_uid: uid
            }
            ... on Contentstack_event_center {
                event_center_uid: uid
            }
            ... on Contentstack_external_resource {
                external_resource_url: url {
                    href
                }
            }
            ... on Contentstack_material {
                material_id: uid
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_article {
                article_id: uid
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_video {
                video_id
                seo_settings {
                    title
                }
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_audio {
                audio_id
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_event {
                event_id: iconnect_event_number
            }
            ... on Contentstack_pubmed {
                pubmed_id: id
            }
            ... on Contentstack_product_glossary {
                product_glossary: uid
            }
            ... on Contentstack_scientific_publications {
                scientific_publications_uid: uid
            }
            ... on Contentstack_myjmc {
                myjmc_id: id
            }
            ... on Contentstack_information_on_demand {
                iod_id: uid
            }
             ... on Contentstack_course {
                course_id: moodle_course_number
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_podcast {
                    podcast_title: title
                    access_control {
                        access_level
                    }
            }
            ... on Contentstack_scientific_publication {
                    sp_detail_id: uid
            }
            ... on Contentstack_tcp_home {
                    page_url: url
            }
        }
        external_link
        file_asset {
            url: transformedUrl
        }
    }
    image {
        ${imageFragment}
    }
}`;

export const imrFragment = `
imedical_review {
    imr {
        code: title
        date_of_preparation
    }
}
`;

export const buttonFieldsFragment = `
color
    link_group {
        anchor_id
        external_link
        file_asset {
            url: transformedUrl
        }
        internal_link {
            ... on Contentstack_article {
                article_id: uid
                regulatory_status {
                    promotional_or_medical
                }
                access_control {
                    access_level
                }
            }
            ... on Contentstack_ask_the_experts {
                ask_the_experts_uid: uid
            }
            ... on Contentstack_audio {
                audio_id
                regulatory_status {
                    promotional_or_medical
                }
                access_control {
                    access_level
                }
            }
            ... on Contentstack_drug_to_drug {
                drug_to_drug_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_event {
                event_id: iconnect_event_number
            }
            ... on Contentstack_event_center {
                event_center_uid: uid
            }
            ... on Contentstack_external_resource {
                external_resource_url: url {
                    href
                }
            }
            ... on Contentstack_information_on_demand {
                iod_id: uid
            }
            ... on Contentstack_learning_center {
                lms_uid: uid
            }
            ... on Contentstack_material {
                material_id: uid
                regulatory_status {
                    promotional_or_medical
                }
                access_control {
                    access_level
                }
            }
            ... on Contentstack_media_center {
                media_center_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_myjmc {
                myjmc_id: id
            }
            ... on Contentstack_news_center {
                news_center_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_page {
                page_url: url
                regulatory_status {
                    promotional_or_medical
                }
                access_control {
                    access_level
                }
            }
            ... on Contentstack_podcast {
                podcast_title: title
                access_control {
                    access_level
                }
            }
            ... on Contentstack_podcast_center {
                podcast_center_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_product_glossary {
                product_glossary: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_pubmed {
                pubmed_id: id
                access_control {
                    access_level
                }
            }
            ... on Contentstack_request_materials {
                request_materials_uid: uid
            }
            ... on Contentstack_scientific_publications {
                scientific_publications_uid: uid
            }
            ... on Contentstack_scientific_publication {
                    sp_detail_id: uid
                }
            ... on Contentstack_course {
                course_id: moodle_course_number
                regulatory_status {
                    promotional_or_medical
                }
            }
            ... on Contentstack_service_overview {
                service_overview_uid: uid
            }
            ... on Contentstack_specialties {
                specialties_uid: uid
                access_control {
                    access_level
                }
            }
            ... on Contentstack_tcp_home {
                page_url: url
            }
            ... on Contentstack_transfer_of_value {
                transfer_of_value_uid: uid
            }
            ... on Contentstack_video {
                video_id
                seo_settings {
                    title
                }
                regulatory_status {
                    promotional_or_medical
                }
                access_control {
                    access_level
                }
            }
        }
        link_continued
        internal_link_parameters
        link_locale
    }
    text
    variant
`;

export const buttonFragment = `
button {
    ${buttonFieldsFragment}
}`;

export const buttonCollectionFragment = `
button_collection {
    horizontal_alignment
    buttons {
        ${buttonFragment}
    }
}`;

export const imageGalleryFragment = `
... on Contentstack_component_image_gallery {
    id
    internal {
        type
    }
    gallery_content {
        images {
            title
            url
            description
            ${imageFragment}
        }
    }
    display_options {
        show_display_title
        enable_lightbox
    }
    display_title
}`;

export const playerFragment = `
player {
    media {
        ... on Contentstack_audio {
            uid
            title
            display_title
            summary
            detail
            media_source
            audio_id
            updated_at
            uid
            asset_reference {
                title
                uid
                url
            }
            poster_image {
                ${imageFragment}
            }
            ${imrFragment}
            publication_date
        }
        ... on Contentstack_video {
            title
            display_title
            summary
            detail
            media_source
            video_id
            updated_at
            poster_image {
                ${imageFragment}
            }
            seo_settings {
                title
            }
        }
    }
    show_title
    use_videoid
    show_summary
    show_publication_date
    show_imr_code
    show_download_link
}`;

export const textFragment = `
text {
    link_management
    textual_content
    number_of_columns
    automatic_columns_division
}`;

export const footnotesFragment = `
footnotes {
    text
    mapped_text
    text_without_footnotes
    footnotes {
        index
        indexInText
        item
        text
    }
}`;

export const smpcFragment = `
smpc_reference {
    uid
    title
    display_title
    smpc_source
    external_link
    generate_detail_page
    file_asset {
        url: transformedUrl
    }
    page_reference {
        ... on Contentstack_page {
            page_url: url
            regulatory_status {
                promotional_or_medical
            }
        }
        ... on Contentstack_material {
            material_id: uid
            regulatory_status {
                promotional_or_medical
            }
        }
    }
}`;
