import React from "react";
import style from "./style.module.scss";
import { Icon, jnjSearch } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { mdiMagnify } from "@mdi/js";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { useMediaQuery, BreakPoint } from "@jmc/solid-design-system/src/hooks/useMediaQuery";
import { BaseItem } from "@algolia/autocomplete-core";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export const Highlight = ({
    attribute,
    item,
}: {
    attribute: string;
    item: { _highlightResult: BaseItem };
}): JSX.Element => {
    const isMobile = useMediaQuery(BreakPoint.md);
    const { jnjFullBranded } = useJnjBranding();

    return (
        <div className={style.result}>
            <Icon
                data-test-id={`search-box-result-icon`}
                icon={jnjFullBranded ? jnjSearch : mdiMagnify}
                type={jnjFullBranded ? "jnj" : "mdi"}
                verticalAlign="middle"
                color="text-light"
                size={isMobile || jnjFullBranded ? "medium" : "xs"}
            />
            <Typography color="light" size={isMobile || jnjFullBranded ? "m" : "s"}>
                <div
                    className={style.highlight}
                    dangerouslySetInnerHTML={{ __html: item?._highlightResult[attribute]?.value }}
                />
            </Typography>
        </div>
    );
};

export default Highlight;
