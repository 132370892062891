import React from "react";
import { AutoCompleteType } from "../types";
import style from "./style.module.scss";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import { mdiClose, mdiHistory } from "@mdi/js";
import classnames from "classnames";
import { Icon, jnjCloseNaked, jnjHistory } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

const clearRecentSearches = (): void => {
    if (window.localStorage.getItem("AUTOCOMPLETE_RECENT_SEARCHES:search")) {
        window.localStorage.removeItem("AUTOCOMPLETE_RECENT_SEARCHES:search");
    }
};

export const RecentSearches = ({
    items,
    autocomplete,
    source,
    plugin,
}: {
    items: Record<string, unknown>[];
    autocomplete: AutoCompleteType;
    source: { sourceId: string };
    plugin: { data: { removeItem: (id: string) => void } };
}): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(BreakPoint.md);
    const { jnjFullBranded } = useJnjBranding();

    if (plugin) {
        return (
            <div data-test-id="previous-searches">
                <div className={style.recent}>
                    <Typography
                        font="title"
                        size={jnjFullBranded ? "xl" : isMobile ? "3xl" : "l"}
                        color={jnjFullBranded ? "light" : "dark"}
                        weight={jnjFullBranded ? "400" : "500"}
                    >
                        {t("Previous searches", { ns: "search" })}
                    </Typography>
                    <Typography
                        data-test-id="clear-all"
                        variant="body"
                        className={style.clear}
                        underline={jnjFullBranded ? false : true}
                        color={jnjFullBranded ? "primary-general" : undefined}
                        onClick={() => {
                            clearRecentSearches();
                            autocomplete.refresh();
                        }}
                        size={isMobile || jnjFullBranded ? "m" : "s"}
                    >
                        {t("Clear all", { ns: "search" })}
                    </Typography>
                </div>
                <ul className="aa-List" {...autocomplete.getListProps()}>
                    {items?.map((item) => (
                        <li
                            key={`search-${source?.sourceId}-${item?.id}`}
                            data-test-id={`previous-search-${item?.label}`}
                            className={classnames("aa-Item", style.recent, style.item)}
                            {...autocomplete.getItemProps({
                                item,
                                source,
                            })}
                        >
                            <Typography color="light" size={isMobile || jnjFullBranded ? "m" : "s"}>
                                <div className={style.resultWrapper}>
                                    <Icon
                                        data-test-id={`search-box-result-icon`}
                                        icon={jnjFullBranded ? jnjHistory : mdiHistory}
                                        size={isMobile || jnjFullBranded ? "medium" : "xs"}
                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                        verticalAlign="middle"
                                        color="text-light"
                                    />{" "}
                                    <span className={style.oneLineEllipsis}>{item?.label}</span>
                                </div>
                            </Typography>
                            <button
                                className={style.deleteRecent}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    plugin.data.removeItem(item?.id as string);
                                    autocomplete.refresh();
                                }}
                            >
                                <Icon
                                    className={classnames(style.deleteRecentIcon)}
                                    data-test-id={`search-box-delete-recent-icon`}
                                    icon={jnjFullBranded ? jnjCloseNaked : mdiClose}
                                    verticalAlign="middle"
                                    color={jnjFullBranded ? "inherit" : "text-light"}
                                    size={isMobile || jnjFullBranded ? "medium" : "xs"}
                                    type={jnjFullBranded ? "jnj" : "mdi"}
                                />
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        );
    } else {
        return null;
    }
};

export default RecentSearches;
