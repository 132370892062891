import React from "react";
import { AutoCompleteType } from "../types";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import classnames from "classnames";
import { mdiMagnify } from "@mdi/js";
import style from "./style.module.scss";
import { Icon, jnjSearch } from "@jmc/solid-design-system/src/components/atoms/Icon/Icon";
import { useTranslation } from "react-i18next";
import Highlight from "../Highlight/Highlight";
import { BreakPoint, useMediaQuery } from "@jmc/solid-design-system/src/hooks/useMediaQuery/useMediaQuery";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export const Suggestions = ({
    items,
    autocomplete,
    source,
    searchQuery,
}: {
    items: Record<string, unknown>[];
    autocomplete: AutoCompleteType;
    source: { sourceId: string };
    searchQuery?: string;
}): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useMediaQuery(BreakPoint.md);
    const { jnjFullBranded } = useJnjBranding();

    return (
        <div data-test-id="most-popular-searches">
            {searchQuery === "" && (
                <div className={style.popular}>
                    <Typography
                        font={"title"}
                        size={jnjFullBranded ? "xl" : isMobile ? "3xl" : "l"}
                        color={jnjFullBranded ? "light" : "dark"}
                        weight={jnjFullBranded ? "400" : "500"}
                    >
                        {t("Most popular searches", { ns: "search" })}
                    </Typography>
                </div>
            )}
            <ul className="aa-List" {...autocomplete?.getListProps()}>
                {items?.slice(0, searchQuery === "" ? 5 : 10)?.map((item, index) => (
                    <li
                        key={`suggestions-${source?.sourceId}-${item?.query}-${index}`}
                        className={classnames("aa-Item", style.item)}
                        data-test-id={`most-popular-search-${item?.query}`}
                        {...autocomplete?.getItemProps({
                            item,
                            source,
                        })}
                    >
                        {!searchQuery ? (
                            <Typography color="light" size={isMobile || jnjFullBranded ? "m" : "s"}>
                                <div className={style.resultWrapper}>
                                    <Icon
                                        data-test-id={`search-box-result-icon`}
                                        icon={jnjFullBranded ? jnjSearch : mdiMagnify}
                                        verticalAlign="middle"
                                        size={isMobile || jnjFullBranded ? "medium" : "xs"}
                                        type={jnjFullBranded ? "jnj" : "mdi"}
                                        color="text-light"
                                    />{" "}
                                    <span className={style.oneLineEllipsis}>{item?.query}</span>
                                </div>
                            </Typography>
                        ) : (
                            <Highlight attribute="query" item={item} />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Suggestions;
