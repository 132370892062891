import React, { ReactElement } from "react";
import { Quote } from "@jmc/solid-design-system/src/components/atoms/Quote/Quote";
import { Typography } from "@jmc/solid-design-system/src/components/atoms/Typography/Typography";
import parse, { domToReact, DOMNode, Element } from "html-react-parser";
import style from "./style.module.scss";
import { Colors, Sizes } from "../../atoms/Typography/Typography";
import { transformSpecialChars } from "../../../utils/specialChars";
import { Table, Tbody, Td, Th, Tr } from "../../organisms/Table/Table";
import classnames from "classnames";
import { useJnjBranding } from "@jmc/utils/hooks/useJnjBranding";

export type TransformFunction = (
    element: ReactElement<any>,
    color?: Colors,
    size?: Sizes,
    children?: string | JSX.Element | JSX.Element[],
    useLargeText?: boolean,
    jnjFullBranded?: boolean,
) => any;

interface TransformMapping {
    [key: string]: TransformFunction;
}

interface Props {
    children: string;
    /** adjust the base color */
    color?: Colors;
    /** Pass a specific size to use (typically inherit) instead of the default Typography size */
    size?: string;
    /** Allows custom tranformation of any element being parsed */
    onTransform?: TransformFunction;
    /** Apply default margins or stick to the global CSS reset */
    margins?: boolean;
    /** For font size change for Cyrillic letters */
    isCyrillic?: boolean;
    /** Rebranded Text blocks need to use a bigger text size then HTML components used in other blocks */
    useLargeText?: boolean;
}

const htmlMapping: TransformMapping = {
    script() {
        return <></>;
    },
    style() {
        return <></>;
    },
    h1(element, color, size, children) {
        return (
            <Typography color={color} variant="h1" {...element.props}>
                {children}
            </Typography>
        );
    },
    h2(element, color, size, children) {
        return (
            <Typography color={color} variant="h2" {...element.props}>
                {children}
            </Typography>
        );
    },
    h3(element, color, size, children) {
        return (
            <Typography color={color} variant="h3" {...element.props}>
                {children}
            </Typography>
        );
    },
    h4(element, color, size, children) {
        return (
            <Typography color={color} variant="h4" {...element.props}>
                {children}
            </Typography>
        );
    },
    h5(element, color, size, children) {
        return (
            <Typography color={color} variant="h5" {...element.props}>
                {children}
            </Typography>
        );
    },
    h6(element, color, size, children) {
        return (
            <Typography color={color} variant="h6" {...element.props}>
                {children}
            </Typography>
        );
    },
    p(element, color, size, children, useLargeText) {
        return useLargeText ? (
            <Typography color={color} size={size} component={"p"} variant="body-02" {...element.props}>
                {children}
            </Typography>
        ) : (
            <Typography color={color} size={size} component={"p"} {...element.props}>
                {children}
            </Typography>
        );
    },
    span(element, color, size, children, useLargeText) {
        return useLargeText ? (
            <Typography
                color={color}
                size={size}
                wordBreak="normal"
                component={"span"}
                variant="body-02"
                {...element.props}
            >
                {children}
            </Typography>
        ) : (
            <Typography variant="span" color={color} wordBreak="normal" size={size} {...element.props}>
                {children}
            </Typography>
        );
    },
    em(element, color, size, children) {
        return (
            <Typography component="em" color="inherit" size="inherit" weight="inherit" font="inherit">
                {children}
            </Typography>
        );
    },
    div(element, color, size, children) {
        return <div {...element.props}>{children}</div>;
    },
    strong(element, color, size, children) {
        return (
            <Typography component="strong" color="inherit" size="inherit" weight="700" font="inherit">
                {transformSpecialChars(children)}
            </Typography>
        );
    },
    li(element, color, size, children, useLargeText) {
        return (
            <li {...element.props}>
                {useLargeText ? (
                    <Typography color={color} size={size} variant="body-02">
                        {transformSpecialChars(children)}
                    </Typography>
                ) : (
                    <Typography color={color}>{transformSpecialChars(children)}</Typography>
                )}
            </li>
        );
    },
    blockquote(element, color, size, children) {
        return <Quote {...element.props}>{children}</Quote>;
    },
    table(element, color, size, children) {
        let { className } = element.props || {};
        className = className?.split(" ");
        const pivotTable = className?.includes("table-pivoted");

        return (
            <div
                data-test-id={pivotTable ? "normal-table" : "scrollbar-table"}
                className={classnames(pivotTable ? style.visibleClass : style.autoClass, style.overflowY, style.table)}
            >
                <Table {...element.props} className={pivotTable ? null : "scrollbar"}>
                    {children}
                </Table>
            </div>
        );
    },
    thead(element, color, size, children) {
        // some how Thead fails here
        return (
            <thead {...element.props}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement<{ inHeader: boolean }>(child as ReactElement, { inHeader: true });
                    }
                })}
            </thead>
        );
    },
    tbody(element, color, size, children) {
        return <Tbody {...element.props}>{children}</Tbody>;
    },
    tr(element, color, size, children) {
        return (
            <Tr {...element.props}>
                {React.Children.map(children, (child) => {
                    if (React.isValidElement(child)) {
                        return React.cloneElement(child as ReactElement);
                    }
                })}
            </Tr>
        );
    },
    th(element, color, size, children, useLargeText, jnjFullBranded) {
        return (
            <Th key={element.key} {...element.props}>
                <Typography
                    variant={jnjFullBranded ? "body-02" : "h6"}
                    color={jnjFullBranded ? "dark" : "inherit"}
                    weight={jnjFullBranded ? "700" : "600"}
                >
                    {children}
                </Typography>
            </Th>
        );
    },
    td(element, color, size, children) {
        return <Td {...{ ...element.props, children: transformSpecialChars(children) }} />;
    },
    img(element) {
        return <img style={{ maxWidth: "100%" }} alt={element?.props?.alt || ""} {...element.props} />;
    },
};

export const Html = ({
    children,
    color,
    size = null,
    isCyrillic = false,
    onTransform = () => null,
    margins = false,
    useLargeText = false,
    ...other
}: Props) => {
    const { jnjFullBranded } = useJnjBranding();

    const transform = (node: DOMNode) => {
        if (!node || !["tag", "text"].includes(node.type)) {
            return <></>;
        }

        if (jnjFullBranded) {
            // Skip text nodes that contain only whitespace
            if (node.type === "text" && node.data?.match(/^\s*$/)) {
                return <></>;
            }

            // Skip <p> or <span> tags that contain only whitespace
            if (node.type === "tag" && node.name === "p") {
                const element = node as Element;
                const reactChildren = element.children ? domToReact(element.children) : [];
                // Ensure reactChildren is always an array
                const reactChildrenArray = Array.isArray(reactChildren) ? reactChildren : [reactChildren];

                // Check if the <p> tag contains only whitespace
                if (reactChildrenArray.every((child) => typeof child === "string" && child.match(/^\s*$/))) {
                    return <></>;
                }
            }
        } else {
            if (node?.type === "text" && node?.data?.match(/^\s*$/)) {
                if (
                    (node?.prev?.name === "p" && node?.next?.name === "p") ||
                    (node?.prev?.name === "span" && node?.next?.name === "span")
                ) {
                    // skip p/span tags that contain nothing but whitespace
                    return <></>;
                }
            }
        }

        const element = node as Element;

        const reactElement = domToReact([element]) as JSX.Element;
        const options = { replace: transform, trim: false };
        const reactChildren = element.children ? domToReact(element.children, options) : [];

        const transformedElement = onTransform(reactElement, color, size, reactChildren, useLargeText, jnjFullBranded);

        if (transformedElement) {
            return transformedElement;
        }
        if (!node.parent && node.type === "text") {
            const text = node as unknown as Text;
            return useLargeText ? (
                <Typography
                    component="span"
                    color="inherit"
                    size={isCyrillic ? "default" : "inherit"}
                    weight="inherit"
                    font="inherit"
                    variant="body-02"
                >
                    {text.data}
                </Typography>
            ) : (
                <Typography
                    component="span"
                    color="inherit"
                    size={isCyrillic ? "default" : "inherit"}
                    weight="inherit"
                    font="inherit"
                >
                    {text.data}
                </Typography>
            );
        }

        const transformer = htmlMapping[element.name];
        if (transformer) {
            const reactElement = domToReact([element]) as JSX.Element;

            if (element.name === "img") {
                const parent = node.parent as Element;
                const elementStyle = parent?.attribs?.style;

                if (elementStyle) {
                    if (elementStyle.includes("float: left") || elementStyle.includes("float: none")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: flex-start";
                    }
                    if (elementStyle.includes("margin: auto")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: center";
                    }
                    if (elementStyle.includes("float: right")) {
                        parent.attribs.style =
                            "margin-left: 0; display: flex; align-items: center; justify-content: flex-end";
                    }
                }
            }
            if (element.attribs?.class) {
                const whiteListedClassNames: string[] = [
                    "text-small",
                    "color-primary",
                    "color-secondary",
                    "color-accent",
                    "color-accent-2",
                    "aepi-box",
                    "align-left",
                    "align-center",
                    "align-right",
                    "scrollbar",
                    "color-black",
                ];

                const classes = element.attribs.class.split(" ");

                const allowedClassName = classes
                    .filter((className: string) => whiteListedClassNames.includes(className))
                    .map((className: string) => style[className]);

                const transformedElm = transformer(reactElement, color, size, reactChildren, useLargeText);
                return React.cloneElement(transformedElm, {
                    "data-test-id": `HelperClasses.${element.attribs.class}`,
                    ...(classes.includes("rtl") ? { dir: "rtl" } : classes.includes("ltr") && { dir: "ltr" }),
                    ...{
                        className: classnames(
                            allowedClassName,
                            element?.name === "table" && jnjFullBranded ? transformedElm?.props.className : null,
                        ),
                    },
                });
            }

            return transformer(reactElement, color, size, reactChildren, useLargeText, jnjFullBranded);
        }
    };
    //Replace < to &lt
    children = children?.replace(/<(?![^<]+>)/g, "&lt;");

    const output = children ? parse(children, { replace: transform, trim: true }) : null;

    return margins ? (
        <div {...other} className={style.default_margins}>
            {output}
        </div>
    ) : (
        <div {...other}>{output}</div>
    );
};

export default Html;
